<template>
  <a-modal v-model="creativeVisible" title="素材详情页" @onCancel="handleCancel" :footer="null" width="720px">
    <div class="creative-detail-container">
      <div class="detail-left">
        <div class="creative-box">
          <img v-if="+creativeInfo.type === 1" :src="creativeInfo.creativeUrl" alt="" />
          <video v-else :src="creativeInfo.creativeUrl" controls></video>
        </div>
      </div>
      <div class="detail-right">
        <div class="detail-form-item">
          <div class="label">素材ID：</div>
          <div class="value">{{ creativeInfo.cid }}</div>
        </div>
        <div class="detail-form-item">
          <div class="label">广告平台：</div>
          <div class="value">{{ creativeInfo.platName || '--' }}</div>
        </div>
        <div class="detail-form-item">
          <div class="label">素材标题：</div>
          <div class="value">{{ creativeInfo.title || '--' }}</div>
        </div>
        <div class="detail-form-item">
          <div class="label">推广产品：</div>
          <div class="value">{{ creativeInfo.productName || '--' }}</div>
        </div>
        <div class="detail-form-item">
          <div class="label">产品包名：</div>
          <div class="value">{{ creativeInfo.packageName || '--' }}</div>
        </div>
        <div class="detail-form-item">
          <div class="label">落地页：</div>
          <div class="value"><a target="_black" :href="creativeInfo.landingPage || ''">{{ creativeInfo.landingPage || '--' }}</a></div>
        </div>
        <div class="detail-form-item">
          <div class="label">唤醒地址：</div>
          <div class="value"><a target="_black" :href="creativeInfo.deeplinkUrl || ''">{{ creativeInfo.deeplinkUrl || '--' }}</a></div>
        </div>
        <div class="detail-form-item">
          <div class="label">下载地址：</div>
          <a href=""></a>
          <div class="value"><a target="_black" :href="creativeInfo.downloadUrl || ''">{{ creativeInfo.downloadUrl || '--' }}</a></div>
        </div>
        <div class="detail-form-item">
          <div class="label">素材描述：</div>
          <div class="" :title="creativeInfo.description">{{ creativeInfo.description || '--' }}</div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  data () {
    return {}
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    creativeInfo: {
      default: () => ({}),
      type: Object
    }
  },
  watch: {},
  computed: {
    creativeVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    handleCancel () {
      this.creativeVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.creative-detail-container {
  height: 500px;
  border-radius: 10px;
  background-color: #fafafa;
  padding: 10px;
  display: flex;
  flex-wrap: nowrap;
  .detail-right,
  .detail-left {
    flex-grow: 1;
    border-radius: 6px;
    background-color: #fff;
  }
  .detail-right {
    // margin-right: 20px;
    padding: 12px 15px 20px 17px;
    display: flex;
    flex-direction: column;
  }
  .detail-left {
    min-width: 42%;
    margin-right: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    max-width: 290px;
    .creative-box {
      width: 100%;
      background-color: #eee;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      img,
      video {
        max-height: 450px;
        max-width: 100%;
      }
    }
  }
}
.detail-form-item {
  display: flex;
  border-bottom: 1px solid #eee;
  padding: 12px 0;
  .label {
    min-width: 80px;
    text-align: right;
    color: #999;
  }
  .value {
    padding-left: 10px;
    flex-grow: 1;
    width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
